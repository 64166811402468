import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router, NavigationEnd } from '@angular/router';
import { PORTALTYPE } from 'app/core/helpers/enum.helper';
import { decodedToken } from 'app/core/helpers/token.helper';
import { AppLoaderService } from 'app/core/services/app-loader.service';
import { GenerateRouteService } from 'app/core/services/generate-route.service';
import { MessagingService } from 'app/core/services/messaging.service';
import { ToastService } from 'app/core/services/toast.service';
import { NotificationService } from 'app/modules/admin/notification/service/notification.service';
import { NotificationsService } from 'app/modules/employee/notification/service/notification.service';
import { get } from 'lodash';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html'
})
export class NotificationsComponent implements OnInit {
  @Input() notificPanel;



  // Dummy notifications
  notifications 
  portalType: any;
  notificationsList: any;
  employeeType: any;
  count: any;

  // constructor(private router: Router,
  //   private modelService:NotificationService) {}


    
  constructor(private router: Router,
    private modelService: NotificationService,
    private employeeService:NotificationsService,
    private loader: AppLoaderService,
    private generateRouteService: GenerateRouteService,
    private messagingService: MessagingService,
    private toastr: ToastService,

  ) {
    this.reloadNotify()
    this.removeNotificationBasedOnId();
    this.getCurrentNotification()
  }

  
  ngOnInit() {
    this.router.events.subscribe((routeChange) => {
        if (routeChange instanceof NavigationEnd) {
          this.notificPanel.close();
        }
    });
    const token = decodedToken();
    this.portalType = get(token, 'portal') ?? '';
    this.employeeType = get(token, 'employee_type') ?? '';
    if (this.portalType === PORTALTYPE.ADMIN) {
      this.getAllNotification()
    }else if (this.portalType === PORTALTYPE.EMPLOYEEE){
      if(this.employeeType == 1){
        this.getWoodExpertNotification()
      }else if(this.employeeType == 2){
        this.getTeleNotification()
      }
    }

  }


  
  reloadNotify() {
    this.messagingService.reloadNotificationList.subscribe(res => {
      if (res) {
        if (this.portalType === PORTALTYPE.ADMIN) {
          this.getAllNotification()
        }else if (this.portalType === PORTALTYPE.EMPLOYEEE){
          if(this.employeeType == 1){
            this.getWoodExpertNotification()
          }else if(this.employeeType == 2){
            this.getTeleNotification()
          }
        }
      }
    })
  }
  getCurrentNotification() {
    this.messagingService.currentMessage.subscribe(res => {
      if (res) {
        console.log(res);
        this.notifications.unshift(res);
      }
    })
  }

  removeNotificationBasedOnId() {
    this.messagingService.removeMessage.subscribe(res => {
      if (res) {
        console.log(res);
        let index = this.notifications.findIndex(el => el.notification_id == res.notification_id);
        this.notifications.splice(index, 1);
      }
    })
  }


  clearAll(e) {
    e.preventDefault();
    this.notifications = [];
  }

getAllNotification(){
  this.modelService.getAllNotification().subscribe( res =>{
    this.notifications = res.data.filter(el => el.msg_read == 0);
    this.count = this.notifications.length;
    this.generateRouteService.setQueueCount(this.count, true);
    
  })
}

getTeleNotification(){
  this.employeeService.getTeleNotificationList().subscribe( res =>{
    this.notifications = res.data.filter(el => el.msg_read == 0);
    this.count = this.notifications.length;
    this.generateRouteService.setQueueCount(this.count, true);
  })
}

getWoodExpertNotification(){
  this.employeeService.getWoodExpertNotification().subscribe( res =>{
    this.notifications = res.data.filter(el => el.msg_read == 0);
    this.count = this.notifications.length;
    this.generateRouteService.setQueueCount(this.count, true);
  })
}
  viewMore() {
    if (this.portalType === PORTALTYPE.ADMIN) {
      this.router.navigate(['/notification/list']);
    } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
      this.router.navigate(['/employee/notification/list']);
  }

}


clicked(index, row) {
  let obj = {
    id: JSON.stringify([row.notification_id]),
    
  }

  if (this.portalType === PORTALTYPE.ADMIN) {
  this.modelService.setReadStatus(obj)
    .subscribe(res => {
    })
  this.notifications.splice(index, 1);
  this.messagingService.appendQueueCount(0);

  }else if(this.portalType === PORTALTYPE.EMPLOYEEE) {
    if(this.employeeType == 1){
      this.employeeService.setWoodReadStatus(obj)
      .subscribe(res => {
      })
    this.notifications.splice(index, 1);
    this.messagingService.appendQueueCount(0);
    }else if(this.employeeType == 2){
      this.employeeService.setTeleReadStatus(obj)
      .subscribe(res => {
      })
    this.notifications.splice(index, 1);
    this.messagingService.appendQueueCount(0);
    }
   

  }
  // if (this.portalType === PORTALTYPE.ADMIN) {
  //   this.generateRouteService.navigateRouteForGym(row.portal, row.page, row.data);
  // } else if (this.portalType === PORTALTYPE.EMPLOYEEE) {
  //   this.generateRouteService.navigateRouteForDietitian(row.portal, row.page, row.data);
  // } 
}
}