import { AbstractControl, ValidationErrors } from "@angular/forms";

export const APP_VARIABLES = {
    payKey: 'rzp_test_XAwbRDSItDjjI0',
    userId: 'USERID',
    adminProfile: 'ADMINPROFILE',
    isAccountCreated: 'ACCOUNTCREATED',
    login: 'LOGIN',
    table: {
        filtering: {
            pageSizeOptions: [10, 25, 50],
            customSizeOptions: [50, 100, 150, 200, 250, 300, 350, 400, 450, 500]
        },

    },
    intervalCount: 30000,

    config: {
        lang: 'TGLANG',
        dateFormat: 'TGDATEFORMAT',
        dateRawFormat: 'TGDATERAW',
        dateSep: 'TGDATESEP',
        timeFormat: 'TGTIMEFORMAT',
        themeColor: 'TGTHEME',
        themeFont: 'TGFONT',

    },

    validators: {
        image: /(.*?)\.(jpg|jpeg|png|bmp|jfif)$/,
        integer: /^-?(0|[1-9]\d*)?$/,
        removebmp: /(.*?)\.(jpg|jpeg|png|jfif)$/,
        allimage: /(.*?)\.(jpg|jpeg|png|pdf|msword|docx|doc|jfif)$/,
        fileImage: /(.*?)\.(jpg|jpeg|png|pdf|msword|docx|doc)$/,
        emailPattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
        allowOnlyPlusValue: /^[0-9]\d*$/,
        decimalTwoDigitOnly: /^\d{1,1000}(\.\d{1,2})?$/, // allows plus value and two digit
        decimal: /^\-?(\d+\.?\d*|\d*\.?\d+)$/, // allows + or - values 
        sixDigitInteger: /^[0-9]{6}$/,
        tenDigitInteger: /^[0-9]{10}$/,
        aadharNo: /^[0-9]{12}$/,
        alphaNumeric: /^[0-9a-zA-Z]+$/, // RSDFS3454fgfet 
        lettersOnly: /^[A-Za-z]+$/, // ABCabcRtvd
        imageAndPdf: /(.*?)\.(jpg|jpeg|png|bmp|pdf)$/,
        removeWhitespace: /^[^ ][\w\W ]*[^ ]/,
        removeWSWLetter: /^\S$|^\S[\s\S]*\S$/ // remove white space prefix, suffix and center of the letters
    }



}
export class WhiteSpaceValidator {
    static cannotContainSpace(control: AbstractControl): ValidationErrors | null {
        if ((control.value as string).indexOf(' ') >= 0) {
            return { cannotContainSpace: true }
        }
        return null;
    }
}