import { IMenuItem } from "./navigation.model";


export let buywoodMenu: IMenuItem[] = [
  {
    module:['Dashboard'],
    name: "Dashboard",
    type: "link",
    state: "dashboard"
  },
 
  {
    module:['Enquiry'],
    name: "Enquiries",
    type: "link",
    state: "enquiries"
  },
  
  {
    module:['Leads'],
    name: "Leads",
    type: "link",
    state: "leads"
  },
  {
    module:['Product'],
    name: "Product Catalogue",
    type: "link",
    state: "product-catalogue"
  },
  {
    module:['Payment Transaction'],
    name: "Payment Transaction",
    type: "link",
    state: "payment-transaction"
  },

  {
    name: "Reports",
    type: "dropDown",
    module: ["Enquiry Reports","Leads Reports","Payment Reports"],
    state: "",
    sub: [
      { name: "Enquiry Report", module: ["Enquiry Reports"], state: "reports/enquiry" },
      { name: "Leads Report", module: ["Leads Reports"], state: "reports/lead" },
      { name: "Payment Transaction Report", module: ["Payment Reports"], state: "reports/payments" },
    ],
  },


  {
   
    name: "Settings",
    type: "link",
    module:['Employee','Wood purpose','Consultation Fee','User','Role','Permission','Change Password','Activity Log'],
    state: "",
    sub: [
      { name: "Settings", type: 'link', module:['Employee'], state: "settings/employee" },
      { name: "Settings", type: 'link', module:['Wood purpose'], state: "settings/wood" },
      { name: "Settings", type: 'link', module:['Consultation Fee'], state: "settings/consultation-fees" },
      { name: "Settings", type: 'link', module: ['User'], state: "settings/users" },
      { name: "Settings", type: 'link', module: ['Role'], state: "settings/role" },
      { name: "Settings", type: 'link', module:['Permission'], state: "settings/role-permission" },
      { name: "Settings", type: 'link', module: ['Change Password'], state: "settings/change-password" },
      { name: "Settings", type: 'link', module:['Activity Log'], state: "settings/activity-logs" },
    ],
  },


];

export let buywoodExpEmployeeMenu: IMenuItem[] = [
  {
    module:['Enquiry'],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries"
  },
]

export let buywoodEmployeeMenu: IMenuItem[] = [
  {
    module:['Enquiry'],
    name: "Enquiries",
    type: "link",
    state: "employee/enquiries"
  },
  
  {
    module:['Leads'],
    name: "Leads",
    type: "link",
    state: "employee/leads"
  },
]
