import { Routes } from '@angular/router';
import { AuthGuard } from './core/services/auth-guard.service';
import { CompanyAuthGuard } from './core/services/company-auth-guard.service';
import { EmployeeAuthGuard } from './core/services/employee-auth-guard.service';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';

export const rootRouterConfig: Routes = [


  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./modules/admin/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      },
      {
        path: 'employee',
        loadChildren: () => import('./modules/employee/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      },

    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Overview', breadcrumb: 'Overview' }
      },
    
      {
        path: 'enquiries',
        loadChildren: () => import('./modules/admin/enquiries/enquiries.module').then(m => m.EnquiriesModule),
        data: { title: 'Enquiries', breadcrumb: 'Enquiries' }
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/admin/notification/notification.module').then(m => m.NotificationModule),
        data: { title: 'Notification', breadcrumb: 'Notification' }
      },
      {
        path: 'leads',
        loadChildren: () => import('./modules/admin/leads/leads.module').then(m => m.AppLeadsModule),
        data: { title: 'Leads', breadcrumb: 'leads' }
      },
      {
        path: 'product-catalogue',
        loadChildren: () => import('./modules/admin/catalog/catalog.module').then(m => m.AppCatalogModule),
        data: { title: 'Product Catalogue', breadcrumb: 'Product Catalogue' }
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/admin/reports/reports.module").then(
            (m) => m.AppReportsModule
          ),
        data: {
          title: "Reports",
          breadcrumb: "Reports",
        },
      },
      {
        path: "reports/enquiry",
        loadChildren: () =>
          import("./modules/admin/reports/enquiry/enquiry.module").then(
            (m) => m.AppEnquiryModule
          ),
        data: {
          title: "Enquiries Reports",
          breadcrumb: "Enquiries Reports",
        },
      },
      {
        path: "reports/lead",
        loadChildren: () =>
          import("./modules/admin/reports/lead/lead.module").then(
            (m) => m.AppLeadModule
          ),
        data: {
          title: "Leads Reports",
          breadcrumb: "Leads Reports",
        },
      },
      {
        path: "reports/payments",
        loadChildren: () =>
          import("./modules/admin/reports/payments/payments.module").then(
            (m) => m.AppPaymentsModule
          ),
        data: {
          title: "Payment Transaction Reports",
          breadcrumb: "Payment Transaction Reports",
        },
      },
     
      {
        path: 'settings',
        loadChildren: () => import('./modules/admin/settings/settings.module').then(m => m.AppSettingsModule),
        data: { title: 'Settings', breadcrumb: 'Settings' }
      },
      {
        path: 'payment-transaction',
        loadChildren: () => import('./modules/admin/payment/payment.module').then(m => m.AppPaymentModule),
        data: { title: 'Payment', breadcrumb: 'Payment' }
      },
      

    ]
  },

  {
    path: 'employee',
    component: AdminLayoutComponent,
    canActivate: [EmployeeAuthGuard],
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./modules/employee/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'Overview', breadcrumb: 'Overview' }
      },
    
      {
        path: 'enquiries',
        loadChildren: () => import('./modules/employee/enquiries/enquiries.module').then(m => m.EnquiriesModule),
        data: { title: 'Enquiries', breadcrumb: 'Enquiries' }
      },
     
      {
        path: 'leads',
        loadChildren: () => import('./modules/employee/leads/leads.module').then(m => m.AppLeadsModule),
        data: { title: 'Leads', breadcrumb: 'leads' }
      },
      {
        path: 'calender',
        loadChildren: () => import('./modules/employee/calender/calender.module').then(m => m.CalenderModule),
        data: { title: 'Calender', breadcrumb: 'calender' }
      },
      {
        path: 'catalog',
        loadChildren: () => import('./modules/employee/catalog/catalog.module').then(m => m.AppCatalogModule),
        data: { title: 'Catalog', breadcrumb: 'catalog' }
      },
      {
        path: "reports",
        loadChildren: () =>
          import("./modules/employee/reports/reports.module").then(
            (m) => m.AppReportsModule
          ),
        data: {
          title: "Reports",
          breadcrumb: "Reports",
        },
      },
      {
        path: "reports/enquiry",
        loadChildren: () =>
          import("./modules/employee/reports/enquiry/enquiry.module").then(
            (m) => m.AppEnquiryModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
      {
        path: "reports/lead",
        loadChildren: () =>
          import("./modules/employee/reports/lead/lead.module").then(
            (m) => m.AppLeadModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
      {
        path: "reports/payments",
        loadChildren: () =>
          import("./modules/employee/reports/payments/payments.module").then(
            (m) => m.AppPaymentsModule
          ),
        data: {
          title: "Ledger",
          breadcrumb: "Ledger",
        },
      },
     
      {
        path: 'payment-transaction',
        loadChildren: () => import('./modules/employee/payment/payment.module').then(m => m.AppPaymentModule),
        data: { title: 'Payment', breadcrumb: 'Payment' }
      },
      {
        path: 'notification',
        loadChildren: () => import('./modules/employee/notification/notification.module').then(m => m.NotificationModule),
        data: { title: 'Notification', breadcrumb: 'Notification' }
      },

    ]
  },



  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

